import React from "react";
import "../../styling/templates.css";
import Heder from "../../components/navigation-header";
import Footer from "../../components/footer";
import From from "./form";
import MainBanner from "../../images/beautiful-architecture-office-business-building-with-glass-window-shape1.jpeg";

export default function contacts() {
  return (
    <div>
      <Heder />

      <center>
        <div className="section-background">
          <h2 className="contacts-topic">Get in touch with us</h2>
        </div>
      </center>

      <div className="pc">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle4"></div>
        </div>
      </div>
      <div className="mobile">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "700px",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle5"></div>
        </div>
      </div>

      <div style={{ marginTop: "-70px" }}>
        <div className="pc">
          <center>
            <div className="section-background">
              <table style={{ width: "100%", textAlign: "start" }}>
                <tr>
                  <th
                    style={{
                      width: "10%",
                      verticalAlign: "top",
                      padding: "10px",
                    }}
                  >
                    <img
                      src="/images/CONTACTS/Bppl-logo-with-box.jpg"
                      alt="background-image"
                      loading="eager"
                      style={{
                        width: "100%",
                        backgroundSize: "cover",
                      }}
                    />
                  </th>
                  <th
                    style={{
                      width: "30%",
                      verticalAlign: "top",
                      padding: "10px",
                    }}
                  >
                    <br />
                    <p className="contacts-table-topic">Head Office:</p>
                    <p className="contacts-table-topic">
                      BPPL Holdings PLC
                      <div className="contacts-table-para">
                        Level 17, Access Towers, 278/4 Union Place, <br />
                        Colombo 02, Sri Lanka. <br />
                        Tel: <a href="tel:+94112307168">
                          {" "}
                          +94 11 2307168
                        </a> | <a href="tel:+94112307170"> +94 11 2307170</a>
                        <br />
                        Email:{" "}
                        <a href="mailto:info@bpplholdings.com">
                          info@bpplholdings.com
                        </a>
                      </div>
                    </p>
                  </th>
                  <th
                    style={{
                      width: "30%",
                      verticalAlign: "top",
                      padding: "10px",
                    }}
                  >
                    <br />
                    <p className="contacts-table-topic">Factories:</p>
                    <p className="contacts-table-topic">
                      Synthetic Filament Factory
                      <div className="contacts-table-para">
                        Batuvita, Mawgama, <br />
                        Horana, Sri Lanka. <br />
                        Tel: <a href="tel:+94342265489"> +94 342 265 489</a>
                      </div>
                      <br />
                      Recycling Plant & Yarn Plant
                      <div className="contacts-table-para">
                        Lot – 7, Horana EPZ, Boralugoda, <br />
                        Poruwadanda, Sri Lanka. <br />
                        Tel: <a href="tel:+94342255874"> +94 342 255 874</a>
                      </div>
                      <br />
                      Wood, Brush and Mop Factories
                      <div className="contacts-table-para">
                        No. 88, Ratnapura Road, <br />
                        Ingiriya, Sri Lanka <br />
                        Tel: <a href="tel:+94342269908"> +94 34 2269908</a>
                      </div>
                    </p>
                  </th>
                  <th
                    style={{
                      width: "30%",
                      verticalAlign: "top",
                      padding: "10px",
                    }}
                  >
                    <br />
                    <div>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d506976.2831683696!2d79.857464!3d6.920198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25914a902eb45%3A0x7a1b4fadebe404d3!2sBPPL%20Holdings%20PLC%20-Beira%20Brush%20Pvt%20Ltd!5e0!3m2!1sen!2sus!4v1695054188926!5m2!1sen!2sus"
                        width="100%"
                        height="300px"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </th>
                </tr>
              </table>
            </div>
          </center>
        </div>
      </div>

      <div className="mobile">
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <center>
            <img
              src="/images/CONTACTS/Bppl-logo-with-box.jpg"
              alt="logo"
              loading="eager"
              style={{
                width: "50%",
                backgroundSize: "cover",
              }}
            />
            <br />
            {/* Head Office */}
            <br />
            <p className="contacts-table-topic">Head Office:</p>
            <p className="contacts-table-topic">
              BPPL Holdings PLC
              <div className="contacts-table-para">
                Level 17, Access Towers, 278/4 Union Place, <br />
                Colombo 02, Sri Lanka. <br />
                Tel: <a href="tel:+94112307168"> +94 11 2307168</a> |{" "}
                <a href="tel:+94112307170"> +94 11 2307170</a>
                <br />
                Email:{" "}
                <a href="mailto:info@bpplholdings.com">info@bpplholdings.com</a>
              </div>
            </p>

            {/* Factories */}
            <br />
            <p className="contacts-table-topic">Factories:</p>
            <p className="contacts-table-topic">
              Synthetic Filament Factory
              <div className="contacts-table-para">
                Batuvita, Mawgama, <br />
                Horana, Sri Lanka. <br />
                Tel: <a href="tel:+94342265489"> +94 342 265 489</a>
              </div>
              <br />
              Recycling Plant & Yarn Plant
              <div className="contacts-table-para">
                Lot – 7, Horana EPZ, Boralugoda, <br />
                Poruwadanda, Sri Lanka. <br />
                Tel: <a href="tel:+94342255874"> +94 342 255 874</a>
              </div>
              <br />
              Wood, Brush and Mop Factories
              <div className="contacts-table-para">
                No. 88, Ratnapura Road, <br />
                Ingiriya, Sri Lanka <br />
                Tel: <a href="tel:+94342269908"> +94 34 2269908</a>
              </div>
            </p>

            <br />
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d506976.2831683696!2d79.857464!3d6.920198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25914a902eb45%3A0x7a1b4fadebe404d3!2sBPPL%20Holdings%20PLC%20-Beira%20Brush%20Pvt%20Ltd!5e0!3m2!1sen!2sus!4v1695054188926!5m2!1sen!2sus"
                width="100%"
                height="250px"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </center>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f5e3",
            padding: "30px",
            marginTop: "30px",
          }}
        >
          <From />
        </div>
      </div>

      <div className="pc">
        <center>
          <div className="section-background">
            <table
              style={{
                width: "100%",
                textAlign: "start",
                marginTop: "70px",
                marginBottom: "70px",
              }}
            >
              <tr>
                <td
                  className="image-banner6"
                  style={{ width: "50%", verticalAlign: "top" }}
                ></td>

                <td
                  style={{
                    width: "50%",
                    height: "99vh",
                    paddingLeft: "20px",
                    verticalAlign: "top",
                  }}
                >
                  <From />
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>

      <Footer />
    </div>
  );
}
