import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "../../styling/templates.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // This will hold both general form and hCaptcha errors
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
    setErrorMessage(""); // Clear any previous error message when captcha is verified
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Check if the captchaToken is empty
    if (!captchaToken) {
      setErrorMessage("Please complete the hCaptcha verification.");
      return; // Prevent form submission
    }

    const formData = new FormData(event.target);

    // Append static fields
    formData.append(
      "subject",
      formData.get("subject") || "BPPL Contacts Us Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");
    formData.append("h-captcha-response", captchaToken); // Add the hCaptcha token

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const responseData = await res.json();

      if (res.ok && responseData.success) {
        setSuccessMessage("Form submitted successfully!");
        setErrorMessage(""); // Clear any previous error message
        window.location.href = formData.get("redirect");
      } else {
        setErrorMessage(
          responseData.message || "Form submission failed. Please try again."
        );
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} id="bpplholdingscontacts">
        <p className="contact-form">Name:</p>
        <input type="text" name="Name:" required className="inputStyle" />
        <br />
        <p className="contact-form">Email:</p>
        <input type="email" name="Email:" required className="inputStyle" />
        <br />
        <p className="contact-form">Subject:</p>
        <input type="text" name="Subject / Topic:" className="inputStyle" />
        <br />
        <p className="contact-form">Details:</p>
        <textarea
          name="Message:"
          rows="6"
          required
          className="textareaStyle"
        ></textarea>
        <br />
        {/* Add the hCaptcha component */}
        <p className="contact-form">
          Please verify that you are human: <span className="red-star">*</span>
        </p>
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={handleCaptchaVerify}
        />
        <br />
        {/* <input
          type="hidden"
          name="ccemail"
          value="shenali@3cs.solutions; priyankara@3cs.solutions"
        /> */}
        <input
          type="hidden"
          name="ccemail"
          value="info@bpplholdings.com; admin@3cs.lk"
        />
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <input type="checkbox" name="botcheck" style={{ display: "none" }} />
        <input name="from_name" type="hidden" value="BPPL Contacts" />
        <input name="subject" type="hidden" value="BPPL Contacts Us Form" />
        <div className="form-footer">
          <button type="submit" className="btn submitButton btn-lg" id="button">
            Submit
          </button>
          <br />
          <div className="d-success">
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {/* Display both form errors and hCaptcha errors here */}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default App;
